body
{
    width: 100vw;
    min-height: 100vh;
}

.fade-in {
    animation: fadeIn ease .5s;
    -webkit-animation: fadeIn ease .5s;
    -moz-animation: fadeIn ease .5s;
    -o-animation: fadeIn ease .5s;
    -ms-animation: fadeIn ease .5s;
    animation-fill-mode: forwards;
}
@keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fade-out {
    animation: fadeOut ease .5s;
    -webkit-animation: fadeOut ease .5s;
    -moz-animation: fadeOut ease .5s;
    -o-animation: fadeOut ease .5s;
    -ms-animation: fadeOut ease .5s;
    animation-fill-mode: forwards;
}
@keyframes fadeOut {
    0% {
        opacity:1;
    }
    100% {
        opacity:0;
    }
}

@-moz-keyframes fadeOut {
    0% {
        opacity:1;
    }
    100% {
        opacity:0;
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity:1;
    }
    100% {
        opacity:0;
    }
}

@-o-keyframes fadeOut {
    0% {
        opacity:1;
    }
    100% {
        opacity:0;
    }
}

@-ms-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

#shadowRoom,
#content
{
    position: absolute;
    width:100vw;
    min-height:100vh;
    top:0;
    left:0;
}
.room_wrapper
{
    width:100vw;
    min-height:100vh;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
nav
{
    z-index:3;
}
#content
{
    z-index: 2;
}

.room_data
{
    display:none;
}
